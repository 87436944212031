import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Tracking from '@send4br/tracking-module';
import { debounce, TRAKR_WIDGET_ELEMENT_ID } from './mount-strategies/mounter-helpers';
import { DEFAULT_PLATFORM } from './config/mount';
import { MISTER_S_URL } from './config/endpoints';
import mountStrategyFactory from './mount-strategies/strategy-factory';

/**
 * Helper component that do the job of mounting and unmount the app.
 */
export default function AppMounter() {
  const mountApp = (element, order, confirmation) => {
    ReactDOM.render(
      <Tracking order={order} confirmation={confirmation} server={MISTER_S_URL} />,
      element,
    );
  };

  const unmountApp = () => {
    const target = document.querySelector(`#${TRAKR_WIDGET_ELEMENT_ID}`);
    if (target && target.parentNode) {
      target.parentNode.removeChild(target);
    }
  };

  useEffect(() => {
    const scriptElement = document.querySelector('#trakr-script');
    const platform = scriptElement ? scriptElement.getAttribute('data-platform') : DEFAULT_PLATFORM;
    const mountStrategy = mountStrategyFactory(platform || DEFAULT_PLATFORM)(mountApp, unmountApp);

    // grant that the strategy will be called at least once.
    mountStrategy();
    const observer = new MutationObserver(debounce(250, mountStrategy));
    observer.observe(document, {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    });
    return () => observer.disconnect();
  }, []);

  return <></>;
}
